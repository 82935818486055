import RegistrationForm from './RegistrationForm';
import EmailRegistrationForm from './EmailRegistrationForm';
import LoginForm from './LoginForm';
import EmailLoginForm from './EmailLoginForm';
import Verification from './Verification';
import ForgotPassword from './ForgotPassword';
import EventPageButton from './EventPageButton';
import EventHeaderButton from './EventHeaderButton';

window.widgets['RegistrationForm'] = RegistrationForm;
window.widgets['EmailRegistrationForm'] = EmailRegistrationForm;
window.widgets['LoginForm'] = LoginForm;
window.widgets['EmailLoginForm'] = EmailLoginForm;
window.widgets['Verification'] = Verification;
window.widgets['ForgotPassword'] = ForgotPassword;
window.widgets['EventPageButton'] = EventPageButton;
window.widgets['EventHeaderButton'] = EventHeaderButton;


window.ElementreeWidgets("LoginForm", document.getElementById('index'))
window.ElementreeWidgets("EmailLoginForm", document.getElementById('index'))
window.ElementreeWidgets("RegistrationForm", document.getElementById('index'))
window.ElementreeWidgets("EmailRegistrationForm", document.getElementById('index'))
window.ElementreeWidgets("Verification", document.getElementById('index'))
window.ElementreeWidgets("ForgotPassword", document.getElementById('index'))
window.ElementreeWidgets("EventPageButton", document.getElementById('index'))
window.ElementreeWidgets("EventHeaderButton", document.getElementById('index'))




